import { render, staticRenderFns } from "./userfooter.vue?vue&type=template&id=26dcf110&scoped=true&"
import script from "./userfooter.vue?vue&type=script&lang=js&"
export * from "./userfooter.vue?vue&type=script&lang=js&"
import style0 from "./userfooter.vue?vue&type=style&index=0&id=26dcf110&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26dcf110",
  null
  
)

export default component.exports