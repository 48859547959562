<template>
  <div class="userheader">
    <Msg isnemsg="true" />
    <div class="header clearfix">
      <h1 class="logo">
        <a href="/" data-stat="pc.v3.index.logo"
          ><img src="../assets/img/logo.png" alt=""
        /></a>
      </h1>
      <div class="login-info  js-my-account">
        <a
          class="logout align-c js-login-out"
          @click="onlogout()"
          href="javascript:;"
          >退出</a
        >
      </div>
      <div class="main-nav ">
        <router-link to="/user/" :class="menucurrent == 0 ? 'current' : ''"
          >我的工作</router-link
        >
        <a href="javascript:;" v-if="false">历史消息</a>
        <router-link to="/order/list" :class="menucurrent == 2 ? 'current' : ''"
          >我的</router-link
        >
        <a
          class="numMsg"
          @click="onmsgtips"
          href="javascript:;"
          title="消息通知"
        >
          <el-dropdown @command="onshowmsg" @visible-change="onshowmsglist">
            <span class="el-dropdown-link">
              <img class="ldang msglist" src="../assets/img/ding.png" />
              <div class="num9 msglist msgcount ">{{ infonum }}</div>
            </span>
            <el-dropdown-menu slot="dropdown" class="msg_body">
              <el-dropdown-item
                :command="item"
                v-for="item in infolist"
                v-bind:key="item.id"
              >
                <div class="msg_item">
                  <div class="msg_x">
                    <img src="../assets/img/workmsg2.png" />
                  </div>
                  <div class="msg_c">
                    <span class="msg_c_p">{{ item.msgTitle }}</span>
                    <span class="msg_c_time">
                      <img src="../assets/img/workmsg3.png" />
                      <span class="msg_c_span">{{ item.sendTime }}</span></span
                    >
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  usermsgcount,
  usermsgmsgalllist,
  useragreemsg,
  userrefusemsg,
  userlogout
} from '@/api/user';
import Cookies from 'js-cookie';
import Msg from '@/components/msg.vue';
import { removeCookieByName } from '@/utils/helper';
// 引入铃铛组件
export default {
  name: 'app-userheader',
  props: ['menucurrent'],
  components: {
    Msg
  },
  data() {
    return {
      isShow: 0,
      id: '',
      usertype: false,
      infonum: 0,
      // 铃铛数量
      infolist: [],
      // 铃铛列表
      isMessage: false,
      // 消息列表
      fomedata: {}
      // 数据对象
    };
  },
  created() {
    // this.selected(this.$route);
    // this.id = Number(localStorage.getItem('uid'));
    // // 判断是否为个人用户true为个人用户
    // if (JSON.parse(localStorage.getItem('userdatas')).isAssBussManage) {
    //   this.usertype = true;
    // } else {
    //   this.usertype = false;
    // }
    // if (this.id > 0) {
    //   searchuser(this.id).then((res) => {
    //     // 根据用户的id查询此账号的信息保存到localstorage
    //     if (res.data.success) {
    //       localStorage.setItem('userdatas', JSON.stringify(res.data.result));
    //     }
    //   });
    // }
  },
  methods: {
    onmsgtips() {
      if (this.infonum == 0) {
        this.$message('您没有任何消息');
      }
    },
    onlogout() {
      userlogout().then(res => {
        // if (res.data.code === 1) {
        // }
        this.removeCookies();
        location.reload();
      });

      //window.location.href = '/index';
    },
    removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    },
    onshowmsglist() {
      this.onusermsgmsgalllist();
    },
    onshowmsg(command) {
      var that = this;
      this.$confirm(command.msgContent, command.msgTitle, {
        distinguishCancelAndClose: true,
        confirmButtonText: '同意',
        cancelButtonText: '拒绝',
        center: true
      })
        .then(() => {
          useragreemsg({ msgId: command.id }).then(res => {
            if (res.data.code == 1) {
              that.$parent.onusergetcurruserworkspace();
              that.onusermsgmsgalllist();
            } else {
              this.$message.error('处理失败');
            }
          });
        })
        .catch(action => {
          if (action === 'cancel') {
            userrefusemsg({ msgId: command.id }).then(res => {
              if (res.data.code == 1) {
                that.onusermsgmsgalllist();
              } else {
                this.$message.error('处理失败');
              }
            });
          }
        });
    },
    isMessagechange(val) {
      this.isMessage = false;
      this.getinfo();
    },
    selected(route) {},
    particulars(val) {
      // 点击消息菜单的回调0
      this.fomedata = val;
      this.isMessage = true;
    },
    onusermsgcount() {
      usermsgcount({ isUnread: true }).then(res => {
        if (res.data.code == 1) {
          this.infonum = res.data.result;
        }
      });
    },
    onusermsgmsgalllist() {
      usermsgmsgalllist({ isUnread: true }).then(res => {
        if (res.data.code == 1) {
          this.infolist = res.data.result;
        }
      });
    }
  },
  mounted() {
    this.onusermsgcount();
    // this.getinfo();
    // this.settiemNew();
  }
};
</script>
<style scoped>
.header,
.userheader {
  background-color: #fff;
  position: relative;
}
.userheader {
  z-index: 90;
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=180, Color='#cccccc');
  box-shadow: 0 1.5px 2.5px 0 rgba(0, 0, 0, 0.05);
}
.header {
  margin: 0 auto;
  width: 1200px;
}
.header .logo {
  float: left;
  margin-top: 8px;
}
.header .logo img {
  width: 160px;
  margin-top: 10px;
}
.header .main-nav .ldang img {
  overflow: hidden;
  vertical-align: top;
}
.align-c {
  text-align: center;
}

.header a {
  color: #0d7bff;
  text-decoration: none;
}
.header .login-info {
  float: right;
  margin-top: 22px;
  margin-left: 68px;
  font-size: 0;
}
.header .login-info .nav-account {
  display: inline-block;
  vertical-align: 2px;
  color: #353535;
  padding-bottom: 22px;
}
.vip-icon {
  display: inline-block;
  vertical-align: -4px;
  width: 20px;
  height: 20px;
}
.header .login-info .txt {
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
  font-size: 16px;
}
.header .login-info .btn-login,
.header .login-info .logout {
  height: 34px;
  line-height: 34px;
  display: inline-block;
  font-size: 16px;
}
.header .login-info .logout {
  width: 78px;
  border: 1px solid #0d7bff;
  margin-left: 48px;
  border-radius: 4px;
}
.header .main-nav {
  padding-left: 120px;
  float: left;
}
.header .main-nav a {
  position: relative;
  float: left;
  padding: 22px 0;
  font-size: 16px;
  color: #707070;
  line-height: 33px;
  text-align: center;
  font-weight: 700;
  margin-left: 70px;
  display: block;
}
.header .main-nav a.current {
  color: #228bff;
  border-bottom: 4px solid #228bff;
}

.header .main-nav .ldang {
  width: 20px;
  height: 21px;
  left: 315px;
  top: 23px;
  float: left;
  margin-top: 6px;
}
.header .main-nav .num9 {
  min-width: 16px;
  height: 20px;
  /* padding: 0 6px; */
  color: white;
  background-color: #f56c6c;
  left: 339px;
  top: 27px;
  border-radius: 10px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  white-space: nowrap;
  float: left;
  margin-top: 6px;
  line-height: 17px;
  min-width: 20px;
  padding: 2px;
  box-sizing: border-box;
  font-size: 14px;
}
.header .main-nav a:nth-of-type(1) {
  margin-left: 0;
}
.clearfix:after,
.wrap:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.workMessages {
  width: 270px;
  border-radius: 3px;
  background-color: #e4ecf3;
  position: absolute;
  left: 290px;
  top: 77px;
  display: none;
  border: 1px solid #bcd4e5;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  left: -240px;
  top: 58px;
}

.workMessages .msg_head {
  width: 268px;
  height: 45px;
  background-color: #ecf2f7;
  line-height: 45px;
  /* border: 1px solid rgb(188 212 229); */
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom: 1px solid #bcd4e5;
  text-align: left;
}
.workMessages .msg_head img {
  width: 20px;
  margin: 0 1px 0 6px;
  position: relative;
  top: 4px;
}
.workMessages .msg_head span {
  color: rgb(128 156 189);
  font-size: 15px;
}
.msg_body {
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 420px;
}
.msg_body .msg_item {
  font-size: 13px;
  width: 99.9%;
  width: 268px;
  height: 64px;
  display: block;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.msg_body .msg_item div {
  float: left;
}
.msg_body .msg_item .msg_x {
  width: 60px;
  text-align: center;
  line-height: 50px;
}
.msg_body .msg_item .msg_x img {
  width: 35px;
  height: 35px;
  margin-top: 9px;
}
.msg_body .msg_item .msg_c {
  display: inline-block;
  font-weight: normal;
  padding-top: 10px;
}

.msg_body .msg_item .msg_c .msg_c_p {
  font-size: 13px;
  display: block;
  font-weight: normal;
  line-height: 20px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}
.msg_body .msg_item .msg_c img {
  width: 16px;
  position: relative;
  top: 3px;
}
.msg_body .msg_item .msg_c_time {
  display: block;
  text-align: left;
  line-height: 20px;
}
.msg_body .msg_item .msg_c .msg_c_span {
  font-size: 12px;
  margin-left: 4px;
  line-height: 25px;
  font-weight: normal;
}
.msg_body.el-dropdown-menu {
  padding: 0;
}
.msg_body.el-dropdown-menu:last-child {
  border: none;
}
</style>
