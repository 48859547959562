<template>
  <div class="userleft">
    <!-- 左侧导航 -->
    <div class="side-box">
      <!-- 左侧导航 -->
      <div class="account-side account-page">
        <div class="account-myinfo clearfix">
          <div class="avar f-l">
            <el-avatar shape="circle" :size="80" :src="userAvatar"></el-avatar>
          </div>
          <div class="info f-l">
            <p class="name" bind_name="1">
              &nbsp;
              <router-link to="/user/accountset" class="">
                {{ userNickName }}</router-link>
              <a href="javascript:;" @click="qrcodedialogVisible = true" class="userqrcode icon icon-zujian-icon-18">
              </a>
            </p>
            <div v-if="false" class="vip-tag" id="account_info_vip_wrap"></div>
          </div>
        </div>
        <ul class="account-nav">
          <li class="li" :class="currindex == 3 ? 'cur' : ''">
            <router-link to="/order/list" class="content_button"><i class="card icon-dingdan order"></i>订单管理
            </router-link>
          </li>

          <li class="li" :class="currindex == 4 ? 'cur' : ''">

            <div v-if="userinfo != null && userinfo.iDcardAuthStatus == 1">
              <router-link to="/user/pub/index" class="content_button"><i
                  class="card icon-fabu pub"></i>发布管理</router-link>
            </div>
            <div v-else>
              <a href="javascript:;" style="padding-left:16px;color:#c7c7c7"><i></i>发布管理</a>
            </div>

          </li>
          <li class="li" :class="currindex == 8 ? 'cur' : ''" v-if="companyPostList.length > 0">
            <router-link to="/user/company/index" class="content_button"><i class="card icon-qiye order"></i>企业名片管理
            </router-link>
            <span href="javascript:;" v-if="false" @click="comqrcodedialogVisible = true"
              class="comqrcode icon  icon-zujian-icon-18">
            </span>
          </li>
          <li class="li" :class="currindex == 9 ? 'cur' : ''">
            <router-link to="/user/accountset" class="content_button"><i class=" card icon-guanlishebei pub"></i>账户设置
            </router-link>
          </li>
          <li class="li" :class="currindex == 6 ? 'cur' : ''">
            <router-link to="/user/shipping/shippinglist" class="content_button"><i class="icon-dizhi card"></i>地址管理
            </router-link>
          </li>
          <li class="li " :class="currindex == 1 ? 'cur' : ''">
            <div v-if="userinfo != null && userinfo.iDcardAuthStatus == 1">
              <router-link to="/user/enterpriselist" class="content_button"><i class="icon-renzheng card"></i>企业认证
              </router-link>
            </div>
            <div v-else>
              <a href="javascript:;" style="padding-left:16px;color:#c7c7c7"><i class="icon-renzheng card"></i>企业认证</a>
            </div>
          </li>
          <li class="li second" :class="currindex == 0 ? 'cur' : ''" v-if="false">
            <router-link to="/user/accountset" class="content_button"><i class="my"></i>个人信息</router-link>
          </li>
          <li class="li second" :class="currindex == 10 ? 'cur' : ''" v-if="false">
            <router-link to="/user/info/certificationinfo" class="content_button"><i class="my"></i>实名认证</router-link>
          </li>
          <li class="li second" v-if="false" :class="currindex == 7 ? 'cur' : ''">
            <router-link to="/user/data/index" class="content_button"><i class="card"></i>数据管理</router-link>
          </li>
          <li class="li second" :class="currindex == 5 ? 'cur' : ''" v-if="false">
            <div v-if="userinfo != null && userinfo.iDcardAuthStatus == 1">
              <router-link :to="{
                  path: '/jobwanted/myResumeperfect',
                  query: { id: candidateId }
                }" class="content_button" v-if="isResume"><i class="resume"></i>个人简历</router-link>
            </div>
            <div v-else>
              <a href="javascript:;" style="padding-left:16px;color:#c7c7c7"><i></i>个人简历</a>
            </div>
          </li>
          <li class="li second" :class="currindex == 11 ? 'cur' : ''" v-if="false">
            <div v-if="userinfo != null && userinfo.iDcardAuthStatus == 1">
              <router-link to="/jobwanted/resumehistory" class="content_button"><i class="card"></i>求职管理</router-link>
            </div>
            <div v-else>
              <a href="javascript:;" style="padding-left:16px;color:#c7c7c7"><i></i>求职管理</a>
            </div>
          </li>
          <li class="li second" :class="currindex == 2 ? 'cur' : ''" v-if="false">
            <div v-if="userinfo != null && userinfo.iDcardAuthStatus == 1">
              <router-link to="/user/authenticationlist" class="content_button"><i class="card"></i>专业职称认证</router-link>
            </div>
            <div v-else>
              <a href="javascript:;" style="padding-left:16px;color:#c7c7c7"><i></i>专业职称认证</a>
            </div>
          </li>

        </ul>
      </div>
    </div>
    <el-dialog :visible.sync="qrcodedialogVisible" class="qrcodebig" center title="二维码名片" top="25vh" width="350px"
      :before-close="handleClose">
      <el-row>
        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark" style="padding-left:15px">
                <div>
                  <div class="avatarbox">
                    <div class="avar f-l">
                      <el-avatar shape="circle" :size="60" :src="userAvatar"></el-avatar>
                    </div>
                  </div>
                  <div class="namebox">
                    <span>{{ userNickName }} </span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bigqrcode">
                <img class="main-nav__qrcodebig__img" :src="bigqrcodeurl" width="200" height="200" />
              </div>
            </el-col>
          </el-row>
          <el-row v-if="false">
            <el-col :span="24">
              <div class="grid-content qrcode-tips">
                扫一扫上面的二维码图案
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog :visible.sync="comqrcodedialogVisible" class="qrcodebig" center title="企业名片二维码" top="25vh" width="350px"
      :before-close="handlecomqrcode">
      <el-row>
        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark" style="padding-left:15px">
                <div>
                  <div class="avatarbox">
                    <div class="avar f-l">
                      <el-avatar shape="circle" :size="60" :src="userAvatar"></el-avatar>
                    </div>
                  </div>
                  <div class="namebox">
                    <span>{{ userNickName }} </span>
                    <!-- <span style="font-size:14px;line-height:20px;display:inline-block;padding:5px 0;">企业管二维码</span> -->
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bigqrcode">
                <img class="main-nav__qrcodebig__img" :src="bigcomqrcodeurl" width="200" height="200" />
              </div>
            </el-col>
          </el-row>
          <el-row v-if="false">
            <el-col :span="24">
              <div class="grid-content qrcode-tips">
                扫一扫上面的二维码图案
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
import {
  usergetcurruserinfo,
  utilsgetuserqrcodebase64,
  utilsgetuserpostandtitleqrcodebase64,
  getuserpostandtitle,
} from '@/api/user';
import { candidateId } from '@/api/jobwant';
import request from '@/utils/request';
export default {
  name: 'app-userleft',
  props: ['currindex'],
  data() {
    return {
      companyPostList: [],
      qrcodedialogVisible: false,
      comqrcodedialogVisible: false,
      userAvatar: require('@/assets/img/defaultheadpic.png'),
      userNickName: '',
      userinfo: null,
      isShow: 0,
      id: '',
      usertype: false,
      infonum: 0,
      // 铃铛数量
      infolist: [],
      // 铃铛列表
      isMessage: false,
      // 消息列表
      fomedata: {},
      // 数据对象
      user_account: '',
      // 判断是否是已创建过得简历
      isResume: false,
      // 简历id
      candidateId: 0,
      bigqrcodeurl: '',
      bigcomqrcodeurl: '',
    };
  },
  created() {
    var that = this;
    this.user_account = getCookieByName('user_account');
    this.userNickName = this.user_account;
    let accessToken = getCookieByName('access_token');
    if (accessToken) {
      this.userAccount = getCookieByName('user_account');
      usergetcurruserinfo().then((res) => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
          if (that.userinfo.userAvatar) {
            that.userAvatar = that.userinfo.userAvatar;
          }
          if (that.userinfo.userNickName) {
            that.userNickName = this.userinfo.userNickName;
          }
        }
      });
    }
    this.getCandidateId();
    this.onutilsgetuserqrcodebase64();
    this.onutilsgetuserpostandtitleqrcodebase64();
    this.ongetuserpostandtitle();
  },
  methods: {
    ongetuserpostandtitle() {
      let params = { desUId: getCookieByName('user_id') };
      getuserpostandtitle(params).then((res) => {
        if (res.data.result !== null) {
          this.companyPostList = res.data.result.companyPostList;
        }
      });
    },
    handleClose() {
      this.qrcodedialogVisible = false;
    },
    handleqrcode() {
      this.qrcodedialogVisible = true;
    },
    handlecomqrcode() {
      this.comqrcodedialogVisible = false;
    },
    getinfo() {
      getinformationlist({
        consumerId: localStorage.getItem('uid'),
        endDate: '',
        page: 0,
        pagination: false,
        rows: 0,
        startDate: '',
        typeCode: '',
      }).then((res) => {
        this.infonum = res.data.result.rows.length;
        this.infolist = res.data.result.rows;
      });
    },
    // 根据完善信息id进行判断是否进入完善页面
    getCandidateId() {
      candidateId().then((res) => {
        console.log(res.data.result);
        if (res.data.result !== null && res.data.result > 0) {
          this.isResume = true;
          this.candidateId = res.data.result;
        }
      });
    },
    onutilsgetuserqrcodebase64() {
      let params = { desUId: getCookieByName('user_id') };
      utilsgetuserqrcodebase64(params).then((res) => {
        if (res.data.result !== null) {
          this.bigqrcodeurl = 'data:image/jpeg;base64,' + res.data.result;
        }
      });
    },
    onutilsgetuserpostandtitleqrcodebase64() {
      let params = { desUId: getCookieByName('user_id') };
      utilsgetuserpostandtitleqrcodebase64(params).then((res) => {
        if (res.data.result !== null) {
          this.bigcomqrcodeurl = 'data:image/jpeg;base64,' + res.data.result;
        }
      });
    },
  },
};
</script>
<style scoped>
.accountset .side-box {
  float: left;
  position: relative;
  width: 240px;
  min-height: 700px;
  background: #fff;
  background-image: linear-gradient(160deg, #fbfcfd, #f0f4f8);
}
.accountset .account-side {
  position: relative;
  z-index: 1;
}
.accountset .account-myinfo {
  padding: 20px;
  font-size: 20px;
}
.accountset .account-myinfo .avar {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
.accountset .account-myinfo .info {
  text-align: center;
}
.accountset .account-myinfo .avar >>> .el-avatar {
  overflow: inherit;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.accountset .account-myinfo .avar >>> .el-avatar img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.accountset .account-myinfo .avar >>> .el-image__inner--center {
  transform: none;
}

.accountset .account-myinfo .name {
  font-size: 20px;
  color: #353535;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 60px;
}
.accountset .account-myinfo .name a {
  color: #353535;
}
.accountset .account-myinfo .vip-tag {
  display: inline-block;
  width: 86px;
  height: 21px;
}
.accountset .account-nav {
  margin-top: -1px;
}
.accountset .main-nav__qrcode__img {
  position: relative;
  top: 2px;
  margin-left: 5px;
}
.accountset .qrcodebig .avatarbox {
  width: 70px;
  float: left;
}
.accountset .qrcodebig >>> .el-avatar > img {
  width: 100%;
}
.accountset .qrcodebig .namebox {
  float: left;
}
.accountset .qrcodebig .namebox span {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: 10px;
}
.accountset .account-nav li {
  height: 50px;
  line-height: 50px;
  position: relative;
}

.accountset .account-nav li.second {
  height: 40px;
  line-height: 40px;
}

.accountset .account-nav li.second a {
  font-weight: normal;
  font-size: 14px;
}

.accountset .account-nav li a {
  display: block;
  font-size: 16px;
  color: #404040;
  padding-left: 16px;
  font-weight: 700;
}
.accountset .account-nav li a.nocurr {
  cursor: default;
}
.accountset .account-nav li.cur a {
  display: block;
  position: relative;
  background: #fff;
  color: #268eff;
}
.accountset .account-nav li.cur a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 4px solid #268eff;
}
/* .accountset .account-nav li.cur i {
  width: 21px;
  height: 21px;
  display: inline-block;
  margin-right: 16px;
  position: relative;
  top: 4px;
} */
.accountset .bigqrcode {
  text-align: center;
  padding: 20px 0;
}
.accountset .qrcode-tips {
  text-align: center;
  font-size: 14px;
  color: #9c9797;
  margin-top: 5px;
}
.account-nav li a i {
  width: 21px;
  height: 21px;
  display: inline-block;
  margin-right: 6px;
  position: relative;
}

.account-nav li a i.card {
  width: 20px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  font-size: 18px;
}

/*
.account-nav li a i.my {
  width: 21px;
  height: 21px;
  display: inline-block;
  margin-right: 16px;
  position: relative;
  top: 4px;
}
 
.account-nav li a i.detail,
.acount-detail {
  background-position: 0 -116px;
  background-image: url('../assets/img/account-ico.png');
  width: 18px;
  margin-right: 13px;
}
.account-nav li.cur a i.detail {
  background-position: 0 -141px;
  background-image: url('../assets/img/account-ico.png');
  margin-right: 10px;
}
.account-nav li a i.detail {
  top: 7px;
}
.account-nav li a i.my {
  background-position: 0 -88px;
  background-image: url('../assets/img/account-ico.png');
}
.account-nav li.cur a i.my {
  background-position: 0 -61px;
  background-image: url('../assets/img/account-ico.png');
}
.account-nav li a i.order {
  background-position: 0 -255px;
  background-image: url('../assets/img/account-ico.png');
}
.account-nav li.cur a i.order {
  background-position: 0 -226px;
  background-image: url('../assets/img/account-ico.png');
}

.account-nav li a i.pub {
  background-position: 0 -315px;
  background-image: url('../assets/img/account-ico.png');
}
.account-nav li.cur a i.pub {
  background-position: 0 -284px;
  background-image: url('../assets/img/account-ico.png');
}
.account-nav li a i.resume {
  background-position: 0 -369px;
  background-image: url('../assets/img/account-ico.png');
}
.account-nav li.cur a i.resume {
  background-position: 0 -341px;
  background-image: url('../assets/img/account-ico.png');
}

.account-nav li a i.card {
  width: 20px;
  height: 18px;
  display: inline-block;
  margin-right: 14px;
  position: relative;
  top: 4px;
  left: -1px;
}
.account-nav li.cur a i.card {
  background-position: 0 -195px;
  background-image: url('../assets/img/account-ico.png');
  margin-right: 13px;
}
.account-nav li a i.card,
.acount-detail {
  background-position: 0 -171px;
  background-image: url('../assets/img/account-ico.png');
} */
.account-content >>> .el-dialog--center .el-dialog__body {
  padding-top: 10px;
}
.account-content .userqrcode {
  margin-left: 5px;
  position: relative;
  top: -1px;
}
.account-content .comqrcode {
  position: absolute;
  top: 0px;
  left: 145px;
  cursor: pointer;
}
.account-content .comqrcode:hover {
  color: #268eff;
}
</style>
