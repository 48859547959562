<template>
  <div class="userfooter">
    <div class="wrap clearfix">
      <div class="aboutus">
        <a href="javascript:;"><i class="i-logo-lct"></i></a>
        <div class="rt">
          <a href="javascript:;" class="about-txt">关于我们</a>
        </div>
        <p class="userfooter-version">
          Copyright 2019-2020 牛豆豆 All Rights Reserved.
        </p>
      </div>
      <div class="contactus">
        <p>
          <i class="index-i-phone"></i
          ><strong class="contact-txt">联系客服</strong>
        </p>
        <div class="tel-num">4006768400（电话）</div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入铃铛组件
export default {
  name: 'app-userfooter',
  created() {}
};
</script>
<style scoped>
.userfooter {
  background: #fff;
  position: relative;
  width: 100%;
  bottom: 0;
  clear: both;
  padding-bottom: 20px;
}
.userfooter::before {
  clear: both;
  content: '';
}
.userfooter .wrap {
  padding: 40px 0 80px;
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.userfooter .aboutus {
  float: left;
}
.i-logo-lct {
  display: inline-block;
  vertical-align: middle;
  width: 67px;
  height: 24px;
  background: url('../assets/img/logo.png') no-repeat;
  background-size: 100% 100%;
  top: -1px;
  position: relative;
}
.userfooter .aboutus .rt {
  display: inline-block;
  vertical-align: -2px;
  font-size: 28px;
  color: #d8d8d8;
  font-weight: 400;
  padding-left: 16px;
  line-height: 1;
}
.userfooter .aboutus .rt .about-txt {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  position: relative;
  padding-left: 17px;
}
.userfooter .aboutus .rt .about-txt::before {
  content: '';
  position: absolute;
  top: 1px;
  left: -2px;
  height: 15px;
  border-left: 1px solid #666;
}
.userfooter .aboutus .userfooter-version {
  font-size: 12px;
  color: #b2b2b2;
  margin-top: 15px;
}
.userfooter .contactus {
  float: right;
  padding-right: 10px;
}
.userfooter .contactus .index-i-phone {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: -7px;
  margin-right: 10px;
}
.userfooter .contactus .index-i-phone,
.index-i-phone {
  background-position: 0 0;
  background: url(//qian-img.tenpay.com/web/v3/img/sprite/index-sf6e01cb16f.c034d6d6.png)
    no-repeat;
}
.userfooter .contactus .contact-txt {
  color: #666;
}
.userfooter .contactus .tel-num {
  font-size: 14px;
  color: #b2b2b2;
  margin-top: 15px;
}
</style>
